<template>

  <div ref="page-container" class="page-container">

    <div class="p-4" style="background-color: white;">

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('ADVANCED_SEARCH.MEMBERS') }}</v-tab>
        <v-tab>{{ $t('ADVANCED_SEARCH.USERS') }}</v-tab>
        <!--<v-tab>{{ $t('ADVANCED_SEARCH.PERSONS') }}</v-tab>-->

        <v-tab-item class="py-8 mr-4">

          <AdvancedSearchMemberEditor
            :show_save_search="true"
            :expand="true"
            ref="advanced-search-member-editor"
            source="MEMBER"
            @on_search_result="on_search_result"
            @on_search_count_result="on_search_count_result"
          />

          <AdvancedSearchTable
            :count="count"
            :members="members"
            :search_options="search_options"
            @per_page_changed="per_page_changed"
            @order_by_changed="order_by_changed"
          />

        </v-tab-item>

        <v-tab-item>

          <AdvancedSearchUserEditor
            :show_save_search="true"
            ref="advanced-search-user-editor"
            source="USER"
            @on_search_result="on_search_result_users"
            @on_search_count_result="on_search_count_result_users"
          />

          <AdvancedSearchUserTable
            :count="count"
            :items="users"
            :search_options="search_options"
            @per_page_changed="per_page_changed"
          />

        </v-tab-item>

        <v-tab-item>

        </v-tab-item>
      </v-tabs>

    </div>

  </div>

</template>


<script>
import axios from 'axios';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import { is_mobile } from '@/core/services/utils';

import AdvancedSearchMemberEditor from '@/view/pages/ml/advanced_search/AdvancedSearchMemberEditor.vue';
import AdvancedSearchUserEditor from '@/view/pages/ml/advanced_search/AdvancedSearchUserEditor.vue';
import AdvancedSearchTable from '@/view/pages/ml/advanced_search/AdvancedSearchTable.vue';
import AdvancedSearchUserTable from '@/view/pages/ml/advanced_search/AdvancedSearchUserTable.vue';


export default {
  name: 'AdvancedSearchView',
  components: {
    AdvancedSearchMemberEditor,
    AdvancedSearchUserEditor,
    AdvancedSearchTable,
    AdvancedSearchUserTable
  },
  props: [],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS', 'sid']),

    is_mobile() {
      return is_mobile();
    },

  },
  mixins: [ toasts ],
  watch: {

  },
  mounted() {

  },
  methods: {
    order_by_changed(order_by) {
      this.search_options.order_by = order_by;
      this.$refs['advanced-search-member-editor'].run_search();
    },

    per_page_changed(per_page, page) {
      this.$refs['advanced-search-member-editor'].change_per_page(per_page, page);
    },

    on_search_result(result, search_options) {
      this.search_options = search_options;
      this.members = result;
    },
    on_search_count_result(count) {
      this.count = count;
    },
    on_search_result_users(result, search_options) {
      this.search_options = search_options;
      this.users = result;
    },
    on_search_count_result_users(count) {
      this.count = count;
    }
  },
  data() {
    return {
      search_options: null,
      members: [],
      users: [],
      count: 0,
      columns: []
    }
  },
}

</script>

<style lang="css" scoped>
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  padding: 4px !important;
}

.row {
  margin-left: 0px !important;
}

.col-form-label {
  margin-top: 6px !important;
}

.page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.fill-rest {
  flex: 1 1 auto;
}


.quick-search-card {
  overflow: scroll;
}

.selected-item-active {
  border-radius: 4px;
  background: #f4fcff;
  color: black;
}

.card-title {
  background-color:#f7f7f7;
  border-bottom:1px solid #dedede;
  padding-bottom: 15px;

}

.card-title-p {
  margin-bottom: 8px !important;
  margin-top: 7px !important;
}


.top-container {
  display: flex;
}

.content {
  flex-grow: 1;
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-left: 8px !important;
}



.drawer-header {
  margin-bottom: -13px !important;
}

.drawer {
  border-right: 1px solid #dddddd !important;
}

.fixed-font {
  font-size: 0.95rem !important;
  font-weight: 500;
  line-height: 1rem;
}

:deep .v-application--wrap {
  min-height: 0px !important;
}

</style>
