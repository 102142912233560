<template>
  <div>

    <!--<AdvancedSearchExport
      class="mb-8"
      :column_names="shown_column_names"
      :search_options="search_options" />-->


    <b-row>
      <b-col cols="12" style="padding-right: 20px;">

        <Multiselect
          class="ml-2"
          v-model="selected_column_keys"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :options="options"
        />

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ count }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="count"
            :per-page="per_page"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <div>
      <b-table
        id="search-result-table"
        class="mt-3 cursor-pointer table-striped"
        sticky-header
        responsive
        striped
        hover
        bordered
        :items="items"
        :fields="shown_table_columns"
        @row-clicked="row_clicked">

      </b-table>
    </div>

  </div>

</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import {
  SET_SEARCH_USERS_RESULT_PREF
} from '@/core/services/store/common.module';
import store from '@/core/services/store';
import AdvancedSearchExport from './AdvancedSearchExport.vue';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

export default {
  name: 'AdvancedSearchUserTable',
  components: {
    Multiselect,
    AdvancedSearchExport,
  },
  mixins: [ toasts ],
  props: {
    fixed_columns: { type: Array, default: null },
    search_options: { type: Object, default: () => ({}) },
    count: { type: Number, default: 0 },
    items: { type: Array, default: [] },
    includeCompanies: { type: Boolean, default: false },
    height: { type: String, default: '800px' }
  },
  emits: ['per_page_changed'],
  data() {
    return {
      selected_column_keys: [],

      currentPage: 1,
      per_page: 100,

      showNumResults: false,
      attributeOptions: [],
      showFields: []
    };
  },
  watch: {
    selected_column_keys() {
      store.dispatch(SET_SEARCH_USERS_RESULT_PREF, this.selected_column_keys);
    },

    per_page() {
      this.$emit('per_page_changed', this.per_page, this.currentPage);
    },

    currentPage() {
      this.$emit('per_page_changed', this.per_page, this.currentPage);
    },

    items() {
      this.showNumResults = true;
    }
  },
  mounted() {

    this.selected_column_keys = store.getters.searchUsersResultPref || [];


  },
  computed: {

    shown_table_columns() {
      return this.fieldDef.filter(item => this.selected_column_keys.includes(item.key));
    },

    options() {
      return this.fieldDef.map(item => {
        return { value: item.key, label: item.label };
      });
    },


    search_result_style() {
      return {
        height: this.height + 'px',
        position: 'relative',
        overflow: 'auto'
      };

    },

    ...mapGetters(['periods', 'currentPeriodId', 'companies', 'profile', 'searchUsersResultPref']),
    fieldDef() {
      return [
        { key: 'id', label: this.$t('USER.USER_ID'), show: true, sortable: true },
        { key: 'in_personnr', label: this.$t('USER.PERSONNR'), show: false, sortable: true },
        { key: 'created_at', label: this.$t('USER.CREATED_AT'), show: false, sortable: true },
        { key: 'firstname', label: this.$t('USER.FIRSTNAME'), show: true, sortable: true },
        { key: 'lastname', label: this.$t('USER.LASTNAME'), show: true, sortable: true },
        { key: 'address', label: this.$t('USER.ADDRESS'), show: true, sortable: true },
        { key: 'email', label: this.$t('USER.EMAIL'), show: true, sortable: true },
        { key: 'phone', label: this.$t('USER.PHONE'), show: true, sortable: true },
      ]
    }
  },
  methods: {


    row_clicked(record, index) {
      if (record.is_member !== undefined && record.is_member !== 1) {
        this.toastr('warning', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_EDIT'));
        return;
      }

      this.user_selected(record.id);


    },

    user_selected(user_id) {
      this.user_id = user_id;
    },

    loadRegions() {
      axios
        .get('https://zip.memlist.se/api/v1/regions_communes')
        .then(res => {
          this.regions = res.data.regions;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_REGIONS'));
        });
    },
    getShowFieldsNames(items) {
      let fields = [];
      items.filter(item => {
        fields.push(item.key);
      });
      return fields;
    },

  }
};
</script>

<style scoped>
.page-count-select {
  min-width: 100px;
}

.b-table-sticky-header {
  overflow-y: hidden;
  max-height: unset;
}

#search-result-table {
  overflow-y: scroll;
}


.table-wrapper-scroll-y {
  display: block;
}
</style>
